import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'gatsby'
import { faFilePdf, faPaperclip, faVoteYea } from '@fortawesome/free-solid-svg-icons'

const Finalist = props => {
  const { name, school, location, link = undefined, voteLink = undefined } = props
  return (
    <div 
      className="finalist" 
      style={{
        display: 'block',
      }}>
      <div className="name">{name}</div>
      <div className="school">{school}</div>
      <div className="location">{location}</div>
      {(link !== undefined) ? (
      <div>
      <div className="essayLink"><a href={`/essays/pdf/${link}`} target="_blank"><FontAwesomeIcon icon={faPaperclip} /> Read Essay</a></div>
      {/* <div className="voteLink"><a href={voteLink} target="_blank"><FontAwesomeIcon icon={faVoteYea} /> Vote for {name}!</a></div> */}
      </div>
      ): ''}
    </div>
  )
}

export default Finalist