import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../components/fontawesome.css"
import Finalist from "../../components/finalist"

import LeftMenu from './leftMenu'
const Finalists = () => (
  <Layout>
    <SEO title="National Student Essay Competition | Finalists" />

    <div className="subpageContainer">

      <LeftMenu active="finalists" />
      <div className="body">
         
        <h1 style={{marginTop: '55px'}}>Congratulations to our finalists!</h1>
        
        <h2></h2>
        <p>
        {/* <a href="https://forms.gle/Wgfcbyych8kuUNwF7" target="_blank">Vote for the essay you think is the best!</a><br /> */}
          The essayist with the most votes will receive a check for $1,000 from MKL Public Relations.</p>

        <h3>UNIVERSITY </h3>

        <div className="finalist-group">
        <Finalist name="Emily Isbell" school="Belmont University" location="Nashville, Tennessee" link="emily-isbell.pdf" voteLink="https://docs.google.com/forms/d/e/1FAIpQLSccr_O7VGXSACbtOxo1PW7KeKO5fBeA_XGaqIMLzYtlY9dRXw/viewform?usp=pp_url&entry.256125240=Essay+by+Emily+Isbell" />
        <Finalist name="Alexander Johansson" school="Snow College" location="Ephraim, Utah" link="alexander-johansson.pdf" voteLink="https://docs.google.com/forms/d/e/1FAIpQLSccr_O7VGXSACbtOxo1PW7KeKO5fBeA_XGaqIMLzYtlY9dRXw/viewform?usp=pp_url&entry.256125240=Essay+by+Alexander+Johansson" />
        <Finalist name="Rachelle Larsen" school="Brigham Young University" location="Provo, Utah" link="rachelle-larsen-byu.pdf" voteLink="https://docs.google.com/forms/d/e/1FAIpQLSccr_O7VGXSACbtOxo1PW7KeKO5fBeA_XGaqIMLzYtlY9dRXw/viewform?usp=pp_url&entry.256125240=Essay+by+Rachelle+Larsen" />
        <Finalist name="A.Z. Chance Martinez" school="Pima Community College" location="Tucson, Arizona" link="chance-martinez-.pdf" voteLink="https://docs.google.com/forms/d/e/1FAIpQLSccr_O7VGXSACbtOxo1PW7KeKO5fBeA_XGaqIMLzYtlY9dRXw/viewform?usp=pp_url&entry.256125240=Essay+by+A.Z.+Chance+Martinez" />
        <Finalist name="Ali Sullivan" school="University of Virginia" location="Charlottesville, Virginia" link="ali-sullivan.pdf" voteLink="https://docs.google.com/forms/d/e/1FAIpQLSccr_O7VGXSACbtOxo1PW7KeKO5fBeA_XGaqIMLzYtlY9dRXw/viewform?usp=pp_url&entry.256125240=Essay+by+Ali+Sullivan" />
        </div>
        
        <h3>GRADES 9-12</h3>
        <div className="finalist-group">
        <Finalist name="Aden Barton" school="Montgomery Bell Academy, 12th Grade" location="Nashville, Tennessee" link="aden-barton.pdf" voteLink="https://docs.google.com/forms/d/e/1FAIpQLSccr_O7VGXSACbtOxo1PW7KeKO5fBeA_XGaqIMLzYtlY9dRXw/viewform?usp=pp_url&entry.256125240=Essay+by+Aden+Barton" />
        <Finalist name="Annelise Best" school="Frisco Liberty High School, 11th Grade" location="Frisco, Texas" link="annalise-best.pdf" voteLink="https://docs.google.com/forms/d/e/1FAIpQLSccr_O7VGXSACbtOxo1PW7KeKO5fBeA_XGaqIMLzYtlY9dRXw/viewform?usp=pp_url&entry.256125240=Essay+by+Annelise+Best" />
        <Finalist name="Josephine Decker" school="Catalina Foothills High School, 11th Grade" location="Tucson, Arizona" link="josephine-decker.pdf" voteLink="https://docs.google.com/forms/d/e/1FAIpQLSccr_O7VGXSACbtOxo1PW7KeKO5fBeA_XGaqIMLzYtlY9dRXw/viewform?usp=pp_url&entry.256125240=Essay+by+Josephine+Decker" />
        <Finalist name="Holton Hennrich" school="Juab High School, 12th Grade" location="Nephi, Utah" link="holton-hennrich-12th.pdf" voteLink="https://docs.google.com/forms/d/e/1FAIpQLSccr_O7VGXSACbtOxo1PW7KeKO5fBeA_XGaqIMLzYtlY9dRXw/viewform?usp=pp_url&entry.256125240=Essay+by+Holton+Hennrich" />
        <Finalist name="Arundhati Oommen" school="West High School, 10th Grade" location="Salt Lake City, Utah" link="arundhati-oommen-10th.pdf" voteLink="https://docs.google.com/forms/d/e/1FAIpQLSccr_O7VGXSACbtOxo1PW7KeKO5fBeA_XGaqIMLzYtlY9dRXw/viewform?usp=pp_url&entry.256125240=Essay+by+Arundhati+Oommen" />
        <Finalist name="Sophia Spiegel" school="Horace Greeley High School, 10th Grade" location="Chappaqua, New York" link="sophia-spiegel.pdf" voteLink="https://docs.google.com/forms/d/e/1FAIpQLSccr_O7VGXSACbtOxo1PW7KeKO5fBeA_XGaqIMLzYtlY9dRXw/viewform?usp=pp_url&entry.256125240=Essay+by+Sophia+Spiegel" />
        </div>
        
        <h3>GRADES 6-8</h3>
        <div class="finalist-group">
        <Finalist name="Daisy Determan" school="Basis Tucson North, 7th Grade" location="Tucson, Arizona" link="daisy-determan.pdf" voteLink="https://docs.google.com/forms/d/e/1FAIpQLSccr_O7VGXSACbtOxo1PW7KeKO5fBeA_XGaqIMLzYtlY9dRXw/viewform?usp=pp_url&entry.256125240=Essay+by+Daisy+Determan" />
        <Finalist name="Marcus Lewis, Jr." school="Rodeo Palms JH, 7th grade" location="Rosharon, Texas" link="marcus-lewis-jr.pdf" voteLink="https://docs.google.com/forms/d/e/1FAIpQLSccr_O7VGXSACbtOxo1PW7KeKO5fBeA_XGaqIMLzYtlY9dRXw/viewform?usp=pp_url&entry.256125240=Essay+by+Marcus+Lewis,+Jr." />
        <Finalist name="Evelyn Link" school="Hawthorne Elementary, 6th Grade" location="Salt Lake City, Utah" link="evelyn-link.-6th.pdf" voteLink="https://docs.google.com/forms/d/e/1FAIpQLSccr_O7VGXSACbtOxo1PW7KeKO5fBeA_XGaqIMLzYtlY9dRXw/viewform?usp=pp_url&entry.256125240=Essay+by+Evelyn+Link" />
        <Finalist name="Eric Lu" school="West High School, 8th Grade" location="Salt Lake City, Utah" link="eric-lu-8th.pdf" voteLink="https://docs.google.com/forms/d/e/1FAIpQLSccr_O7VGXSACbtOxo1PW7KeKO5fBeA_XGaqIMLzYtlY9dRXw/viewform?usp=pp_url&entry.256125240=Essay+by+Eric+Lu" />
        <Finalist name="Frank Connor Runyon" school="Richview Middle School, 7th Grade" location="Clarksville, Tennessee" link="frank-runyon.pdf" voteLink="https://docs.google.com/forms/d/e/1FAIpQLSccr_O7VGXSACbtOxo1PW7KeKO5fBeA_XGaqIMLzYtlY9dRXw/viewform?usp=pp_url&entry.256125240=Essay+by+Frank+Runyon" />
        <Finalist name="Maddie Stone" school="Fox Lane Middle School, 6th Grade" location="Mt. Kisco, New York" link="maddie-stone-6th-grade.pdf" voteLink="https://docs.google.com/forms/d/e/1FAIpQLSccr_O7VGXSACbtOxo1PW7KeKO5fBeA_XGaqIMLzYtlY9dRXw/viewform?usp=pp_url&entry.256125240=Essay+by+Maddie+Stone" />
        </div>

      </div>
    </div>

    

  </Layout>
)

export default Finalists
